import {reportAd} from "@/api/ad";
import {showToast} from "vant";

export function getCookieValue(name) {
    let matches = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return matches ? matches.pop() : '';
}

export function getUrlParameter(name) {
    name = name.replace(/[[]/, '[').replace(/[\]]/, ']'); // 修改这里
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function getDeviceInfo() {
    const ua = navigator.userAgent;
    let deviceType = "unknown";
    if (/iPad/.test(ua)) {
        deviceType = "iPad";
    } else if (/iPod/.test(ua)) {
        deviceType = "iPod";
    } else if (/iPhone/.test(ua)) {
        deviceType = "iPhone";
    } else if (/Android/.test(ua)) {
        deviceType = "Android";
    } else if (/Windows Phone/.test(ua)) {
        deviceType = "Windows Phone";
    }

    return deviceType;
}

export function getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function getTimeZoneOffset() {
    // 获取本地时区偏差，以分钟为单位
    let offset = new Date().getTimezoneOffset();
    // 将分钟转换为小时，并改变符号，因为getTimezoneOffset()的返回值与常规时区表示相反
    return -offset / 60;
}

export function downloadApp(appid, rd, pixel_id, page, comic_id = 0){
    // iOS
    let iosUrlZoomins = 'https://itunes.apple.com/app/id6503939849';
    //let iosUrlNeopad = 'https://itunes.apple.com/app/id6505052823';
    // Android
    let androidUrl = 'https://play.google.com/store/apps/details?id=com.xm.manga';
    let androidUrlGlodream = 'https://play.google.com/store/apps/details?id=com.xm.manga';

    // 检测用户设备
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // iOS
        if( appid == 3 ){
            window.location.href = iosUrlZoomins;
        }else{
            window.location.href = iosUrlZoomins;
        }
    } else if (/android/i.test(userAgent)) {
        // Android
        if( appid == 4 ){
            window.location.href = androidUrlGlodream;
        }else{
            window.location.href = androidUrl;
        }
    } else {
        showToast('Please click on download on your mobile device');
    }

    report(appid, rd, pixel_id, page, comic_id)
}

export function report(appid, rd, pixel_id, page, comic_id = 0){
    let fbp = getCookieValue('_fbp');
    let fbc = getCookieValue('_fbc');

    if( !fbp && !fbc ){
        return;
    }

    let source = getUrlParameter('utm_source');
    let medium = getUrlParameter('utm_medium');
    let ad_id = getUrlParameter('utm_ad_id');
    let adset_id = getUrlParameter('utm_adset_id');
    let campaign_id = getUrlParameter('utm_campaign_id');
    let campaign_name = getUrlParameter('utm_campaign_name');
    let adset_name = getUrlParameter('utm_adset_name');
    let ad_name = getUrlParameter('utm_ad_name');
    let channel = getUrlParameter('utm_channel');
    let device = getDeviceInfo();
    let preferredLanguage = navigator.language;
    let timeZone = getTimeZone();
    let width = window.screen.width;
    let height = window.screen.height;

    const formdata = new FormData()
    formdata.append('appid', appid)
    formdata.append('rd', rd)
    formdata.append('pixel_id', pixel_id)
    formdata.append('comic_id', comic_id)
    formdata.append('source', source)
    formdata.append('medium', medium)
    formdata.append('page', page)
    formdata.append('channel', channel)
    formdata.append('language', preferredLanguage)
    formdata.append('time_zone', timeZone)
    formdata.append('fbp', fbp)
    formdata.append('fbc', fbc)
    formdata.append('ad_id', ad_id)
    formdata.append('adset_id', adset_id)
    formdata.append('campaign_id', campaign_id)
    formdata.append('ad_name', ad_name)
    formdata.append('adset_name', adset_name)
    formdata.append('campaign_name', campaign_name)
    formdata.append('device', device)
    formdata.append('width', width)
    formdata.append('height', height)

    reportAd(formdata).then()
}

export function getAppid(){
    // 检测用户设备
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    let domain_map = {
        'ad': '1',
        'tad': '1',
        'adzm': '2',
        'tadzm': '2',
        'adnp': '3',
        'tadnp': '3',
        'adgl': '4',
        'tadgl': '4',
    }
    let hostname = window.location.hostname
    //let hostname = 'adgl.mellowgra.com'
    const parts = hostname.split('.');
    let prefix = parts[0];

    if (!domain_map[prefix]) {
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return domain_map['adzm'];
        }else{
            return domain_map['ad'];
        }
    }

    return domain_map[prefix]
}


export function getAppidNew(appid){
    if (!appid){
        return getAppid();
    }

    let appid_map = {
        '1': 1, // manga buz
        '2': 2, // zoomins
        '3': 3, // neopad
        '4': 4, // glodream
        '5': 5, // mospic
    }

    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (!appid_map[appid]) {
        if (/android/i.test(userAgent)) {
            return 1;
        }else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 2;
        }else{
            return 1;
        }
    }

    if (/android/i.test(userAgent)) { // 只有一个安卓情况下
        if (appid == 1 || appid == 4){
            return appid;
        }else{
            return 1;
        }
    }else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        if (appid == 2 || appid == 3){
            return appid;
        }else{
            return 2;
        }
    }

}